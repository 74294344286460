<template>
  <b-modal
    id="modal-school-detail"
    ref="refModalSchoolDetail"
    title="Thông tin cơ sở"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >
    <div class="d-flex justify-content-center mb-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder"> Tên cơ sở: </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder"> Địa chỉ: </b-col>
            <b-col class="">
              {{ itemLocal.address }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item v-if="itemLocal.numberStudents">
          <b-row>
            <b-col class="font-weight-bolder"> {{ t("Số học sinh") }}: </b-col>
            <b-col class="">
              {{ Number(itemLocal.numberStudents).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
    <!-- Body -->
  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import AvatarView from "@/views/components/AvatarView.vue";
import useSchoolDetailModal from "./useSchoolDetailModal";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,

    AvatarView,
  },
  props: {
    schoolId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const { t } = useI18nUtils();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useSchoolDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
